import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import global from "./modules/global";
import lists from "./modules/lists";

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    global,
    lists
  },
  plugins: [vuexLocal.plugin]
});
