

















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ToastSuccess extends Vue {
  @Prop()
  messageHtml!: string;
  @Prop({
    default: () => [] as string[]
  })
  items!: string[];
}
