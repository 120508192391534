import { AttributeModel } from "@/models/attribute";
import { CategoryModel } from "@/models/category";
import { MfrModel } from "@/models/mfr";
import { ListsState } from "./types";

export const setCategories = (state: ListsState, value: CategoryModel[]) => {
  state.categories = value;
};

export const setMfrs = (state: ListsState, value: MfrModel[]) => {
  state.mfrs = value;
};
export const setAttrs = (state: ListsState, value: AttributeModel[]) => {
  state.attrs = value;
};
