import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";
import { GlobalState } from "./types";

const state: GlobalState = {
  isLoading: false,
  isLoggedIn: false
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
