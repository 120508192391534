




































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Icon from "@/components/reusable/Icon.vue";
import CategoryService from "@/services/category_service";
import { CategoryModel } from "@/models/category";
import { AuthError } from "@/services/error_service";
import { EventBus } from "@/events";
import { makeSentenceCase } from "@/utility/helpers";

@Component({
  components: {
    Icon
  }
})
export default class ConfirmMove extends Vue {
  @Prop() data!: any;
  @Prop() moveDestination!: any;
  @Prop({
    default: () => [] as any[]
  })
  breadcrumbs!: any[];
  @Prop({ default: "categories" }) type!: string;
  protected categoryService = new CategoryService();
  protected oldDestination = {} as any;
  protected makeSentenceCase = makeSentenceCase;
  protected id = "";

  @Watch("moveDestination")
  protected async onUpdate(): Promise<void> {
    if (this.$route.query.id) {
      this.id = this.$route.query.id as string;
      this.oldDestination = await this.getSingleCategory(
        parseInt(this.id as string, 10)
      );
    } else if (this.$route.query.category) {
      this.id = this.$route.query.category as string;
      this.oldDestination = await this.getSingleCategory(
        parseInt(this.id as string, 10)
      );
    }
  }

  protected async getSingleCategory(id: number): Promise<CategoryModel> {
    let category = {} as CategoryModel;
    try {
      const res: CategoryModel = await this.categoryService.getSingleCategory(
        id
      );
      category = res;
    } catch (err) {
      if (err instanceof AuthError) {
        AuthError.logout();
      } else {
        EventBus.$emit("showError", err.message);
      }
    }
    return category;
  }
  //   newParent: number,
  //   id: number,
  //   name: string,
  //   this.oldDestination = null,
  //   final = false
  protected confirm(): void {
    if (
      !this.oldDestination.id ||
      (this.oldDestination.url &&
        !this.moveDestination.url.includes(this.oldDestination.url) &&
        !this.oldDestination.url.includes(this.moveDestination.url))
    ) {
      this.data.forEach((item: any, index: number) => {
        if (index === this.data.length - 1) {
          this.$emit(
            "sendMoveRequest",
            this.moveDestination.id,
            parseInt(item.id as string, 10),
            item.display_name,
            this.oldDestination,
            true
          );
        } else {
          this.$emit(
            "sendMoveRequest",
            this.moveDestination.id,
            parseInt(item.id as string, 10),
            item.display_name,
            this.oldDestination,
            false
          );
        }
      });
    } else {
      EventBus.$emit("showError", "Cannot move category to it's child.");
    }
  }
}
