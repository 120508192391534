import { EventBus } from "@/events/index";
import { GlobalActions } from "@/store/modules/global/types";
import { StoreModule } from "@/store/types";
import UIkit from "uikit";
import store from "@/store/index";

export class LoginError extends Error {
  static popup(message: string) {
    UIkit.notification({ message: message, status: "danger" });
  }
}

export class GenericError extends Error {
  static popup(message: string) {
    UIkit.notification({ message: message, status: "danger" });
  }
}

export class ConflictError extends Error {
  static popup(message: string) {
    UIkit.notification({ message: message, status: "danger" });
  }
}

export class EntityError extends Error {
  static popup(message: string) {
    UIkit.notification({ message: message, status: "danger" });
  }
}

export class NotFoundError extends Error {
  static popup(message: string) {
    UIkit.notification({ message: message, status: "danger" });
  }
}

export class BadRequestError extends Error {
  static popup(message: string) {
    UIkit.notification({ message: message, status: "danger" });
  }
}

export class InvalidDataCsvError extends Error {
  static popup(message: string) {
    UIkit.notification({ message: message, status: "danger" });
  }
}

export class APIError extends Error {
  constructor(message: string, statusCode?: number) {
    super(message);
    this.statusCode = statusCode;
  }
  public statusCode?: number;
  static popup(message: string) {
    UIkit.notification({ message: message, status: "danger" });
  }
  static restricted() {
    UIkit.notification({
      message: "You have not been granted access to this resource.",
      status: "danger"
    });
  }
}

export class AttributeConflictError extends Error {
  constructor(message: string, details?: any) {
    super(message);
    this.details = details;
  }
  public details?: any;
  static popup(message: string) {
    UIkit.notification({ message: message, status: "danger" });
  }
}

export class AssetConflictError extends Error {
  constructor(message: string, details?: any) {
    super(message);
    this.details = details;
  }
  public details?: any;
  static popup(message: string) {
    UIkit.notification({ message: message, status: "danger" });
  }
}

export class AuthError extends Error {
  static logout(): void {
    store.dispatch(StoreModule.Global + GlobalActions.AddLoggedIn, false);
    const url = encodeURI(process.env.VUE_APP_AUTH_URL);
    EventBus.$emit(
      "showError",
      `Your session has expired. Please <a href='https://beta.ai.mcgit.cc/oauth/2.0/authorize?response_type=code&state=&client_id=17c13818da83af5917655fb0958c9ecf8abda6dc8b9158ef6e09876fcd3a0b55&scope=account%3Aread&redirect_uri=${url}'>click here to login again.</a> You will be automatically redirected in 5 seconds. `
    );
    setTimeout(function() {
      window.location.href =
        "https://beta.ai.mcgit.cc/oauth/2.0/authorize?response_type=code&state=&client_id=17c13818da83af5917655fb0958c9ecf8abda6dc8b9158ef6e09876fcd3a0b55&scope=account%3Aread&redirect_uri=" +
        url;
    }, 7000);
  }
}

// export class AirmaticError extends Error {
//   protected config: object;
//   protected data: string;
//   protected headers: object;
//   protected request: XMLHttpRequest;
//   protected status: number;
//   protected statusText: string;
//   constructor(response: AxiosResponse) {
//     super(response["data"]);
//     this.name = this.constructor.name;
//     this.config = response.config;
//     this.data = response.data;
//     this.headers = response.headers;
//     this.request = response.request;
//     this.status = response.status;
//     this.statusText = response.statusText;
//   }
// }
