import { ListsContext, ListsMutations } from "./types";

import { APIResponse } from "@/models/api_res";
import { AttributeModel } from "@/models/attribute";
import AttributeService from "@/services/attribute_service";
import { AuthError } from "@/services/error_service";
import { CategoryModel } from "@/models/category";
import CategoryService from "@/services/category_service";
import { EventBus } from "@/events/index";
import { MfrModel } from "@/models/mfr";
import MfrService from "@/services/mfr_service";

export const addCategories = async (context: ListsContext) => {
  const categoryService = new CategoryService();
  try {
    const res: APIResponse = await categoryService.getCategories({
      offset: 9999
    });
    const categories: CategoryModel[] = res.results;
    const list = categories.map(item => {
      return { id: item.id, display_name: item.display_name };
    });
    context.commit(ListsMutations.SetCategories, list);
  } catch (err) {
    if (err instanceof AuthError) {
      AuthError.logout();
    } else {
      EventBus.$emit("showError", err.message);
    }
  }
};

// export const removeCategory = async (context: ListsContext) => {

// };

// export const addSingleCategory = async (context: ListsContext) => {

// };

// export const addRetailer = (
//   context: RetailerContext,
//   payload: RetailerSchema
// ): void => {
//   const rets = context.state.retailers;
//   rets.unshift(payload);
//   context.commit(RetailerMutations.SetRetailer, rets);
// };

export const addMfrs = async (context: ListsContext) => {
  const mfrService = new MfrService();
  try {
    const res: APIResponse = await mfrService.getMfrs({ offset: 9999 });
    const mfrs: MfrModel[] = res.results;
    const list = mfrs.map(item => {
      return {
        id: item.id,
        display_name: item.display_name,
        handle: item.handle
      };
    });
    context.commit(ListsMutations.SetMfrs, list);
  } catch (err) {
    if (err instanceof AuthError) {
      AuthError.logout();
    } else {
      EventBus.$emit("showError", err.message);
    }
  }
};

export const addAttrs = async (context: ListsContext) => {
  const attributeService = new AttributeService();
  try {
    const res: APIResponse = await attributeService.getAttributes({
      offset: 9999
    });
    const attributes: AttributeModel[] = res.results;
    const list = attributes.map(item => {
      return {
        id: item.id,
        display_name: item.display_name,
        handle: item.handle
      };
    });
    context.commit(ListsMutations.SetAttrs, list);
  } catch (err) {
    if (err instanceof AuthError) {
      AuthError.logout();
    } else {
      EventBus.$emit("showError", err.message);
    }
  }
};
