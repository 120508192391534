






















import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ToastFail extends Vue {
  @Prop()
  messageHtml!: string;
  @Prop({
    default: () => [] as string[]
  })
  items!: string[];
  @Prop() conflicted!: string[] | undefined;
}
