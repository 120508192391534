import "./assets/css/uikit.theme.min.css";
import "@/components";
import "vue-loading-overlay/dist/vue-loading.css";

import App from "./App.vue";
// @ts-ignore
import Icons from "../node_modules/uikit/dist/js/uikit-icons.min.js";
import Loading from "vue-loading-overlay";
import UIkit from "uikit";
import Vue from "vue";
import Vuelidate from "vuelidate";
import axios from "axios";
import router from "./router";
import store from "./store";
import vmodal from "vue-js-modal";

Vue.use(Loading);
Vue.component("loading", Loading);
Vue.use(vmodal, { dynamic: true, injectModalsContainer: true });
Vue.use(Vuelidate);
// @ts-ignore
UIkit.use(Icons);
Vue.config.productionTip = false;
axios.defaults.withCredentials = true;
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
