/**
 * Globally registered components & mixins
 *
 * These components can be used anywhere and do not need to be imported into individual files
 *
 */

import AddNotification from "@/components/reusable/modals/AddNotification.vue";
import ConfirmDelete from "@/components/reusable/modals/ConfirmDelete.vue";
import ConfirmMove from "@/components/reusable/modals/ConfirmMove.vue";
import ConfirmMoveAsset from "@/components/reusable/modals/ConfirmMoveAsset.vue";
import Icon from "@/components/reusable/Icon.vue";
import Save from "@/components/reusable/Save.vue";
import SaveWarning from "@/components/reusable/modals/SaveWarning.vue";
import ToastError from "@/components/reusable/ToastError.vue";
import ToastFail from "@/components/reusable/ToastFail.vue";
import ToastSuccess from "@/components/reusable/ToastSuccess.vue";
import Vue from "vue";

Vue.component("AddNotification", AddNotification);
Vue.component("ConfirmDelete", ConfirmDelete);
Vue.component("SaveWarning", SaveWarning);
Vue.component("ConfirmMove", ConfirmMove);
Vue.component("ConfirmMoveAsset", ConfirmMoveAsset);
Vue.component("Icon", Icon);
Vue.component("Save", Save);
Vue.component("ToastError", ToastError);
Vue.component("ToastFail", ToastFail);
Vue.component("ToastSuccess", ToastSuccess);
