






























import { Component, Prop, Vue } from "vue-property-decorator";
import Icon from "@/components/reusable/Icon.vue";

@Component({
  components: {
    Icon
  }
})
export default class AddNotification extends Vue {
  @Prop() data!: any;
  @Prop({ default: "item" }) type!: string;
}
