import { AttributeModel } from "@/models/attribute";
import { CategoryModel } from "@/models/category";
import { MfrModel } from "@/models/mfr";

export type ListsState = {
  categories: CategoryModel[];
  mfrs: MfrModel[];
  attrs: AttributeModel[];
};

export type ListsContext = {
  state: ListsState;
  dispatch: (action: ListsActions, payload?: any) => any;
  commit: (mutation: ListsMutations, payload: any) => any;
};

export enum ListsMutations {
  SetCategories = "setCategories",
  SetMfrs = "setMfrs",
  SetAttrs = "setAttrs"
}

export enum ListsActions {
  AddCategories = "addCategories",
  AddMfrs = "addMfrs",
  AddAttrs = "addAttrs"
}

export enum ListsGetters {
  GetCategories = "getCategories",
  GetMfrs = "getMfrs",
  GetAttrs = "getAttrs"
}
