<template>
  <div>
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div class="uk-modal-header uk-flex uk-flex-middle">
      <div class="uk-flex-none">
        <Icon
          :name="'icon: warning; ratio:1.5;'"
          :classNames="'red no-hover uk-margin-small-right'"
        />
      </div>
      <div>
        <h2 class="uk-modal-title uk-margin-remove">
          You have not saved your changes!
        </h2>
      </div>
    </div>
    <div class="uk-modal-body">
      Would you like to continue without saving your changes?
    </div>
    <div class="uk-modal-footer uk-flex uk-flex-right">
      <button
        class=" uk-flex uk-flex-middle uk-button uk-button-default uk-modal-close"
      >
        <Icon
          :name="'close'"
          :classNames="'black no-hover uk-margin-small-right'"
        />No
      </button>
      <button class="uk-button uk-button-primary uk-margin-small-left">
        <Icon
          :name="'check'"
          :classNames="'white no-hover uk-margin-small-right'"
        />Yes
      </button>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import Icon from "@/components/reusable/Icon.vue";

@Component({
  components: {
    Icon
  }
})
export default class SaveWarning extends Vue {}
</script>

<style lang="less" scoped></style>
