export type GlobalState = {
  isLoading: boolean;
  isLoggedIn: boolean;
};

export type GlobalContext = {
  state: GlobalState;
  dispatch: (action: GlobalActions, payload?: any) => any;
  commit: (mutation: GlobalMutations, payload: any) => any;
};

export enum GlobalMutations {
  SetLoading = "setLoading",
  SetLoggedIn = "setLoggedIn"
}

export enum GlobalActions {
  AddLoading = "addLoading",
  AddLoggedIn = "addLoggedIn"
}

export enum GlobalGetters {
  GetLoading = "getLoading",
  GetLoggedIn = "getLoggedIn"
}
