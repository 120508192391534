import { AttributeModel } from "@/models/attribute";
import { CategoryModel } from "@/models/category";
import { MfrModel } from "@/models/mfr";
import { ListsState } from "./types";

export const getCategories = (state: ListsState): CategoryModel[] => {
  return state.categories;
};

export const getMfrs = (state: ListsState): MfrModel[] => {
  return state.mfrs;
};

export const getAttrs = (state: ListsState): AttributeModel[] => {
  return state.attrs;
};
